import { Injectable } from '@angular/core'
import { FilterableInterface } from 'app/shared/filter/datagrid-filterables/filterables.interface'
import { DaletVersionId } from 'enums/dalet'
import { EventPyramidStatus } from 'enums/event-pyramid-status'
import {
    DashboardSearchFilters,
    DashboardSearchFiltersEvents,
    DashboardToolbar,
    DeliverableFilterName,
    MappedFilterName,
    ToolbarFilter,
    ToolbarFilterLabel,
    ToolbarFilterName,
} from 'enums/filters'
import { PublicationOwnerEnum } from 'enums/publication-owner'
import { PublicationStatus } from 'enums/publication-status'
import { PyramidDesk } from 'enums/pyramid-desk'
import { LanguagesService } from 'services/languages/languages'
import { fastClone } from 'tools/fast-clone'

@Injectable()
export class DashboardConstantsService {
    public readonly DATE_FORMAT = 'DD/MM/YYYY'

    public get PUBLICATION_OWNERS(): PublicationOwnerEnum[] {
        return fastClone([PublicationOwnerEnum.Africanews, PublicationOwnerEnum.Euronews])
    }

    public get STATUS_TO_DISPLAY(): PublicationStatus[] {
        return fastClone([
            PublicationStatus.NEW,
            PublicationStatus.EDIT,
            PublicationStatus.SCHEDULED,
            PublicationStatus.TO_REPUBLISH,
            PublicationStatus.ONLINE,
            PublicationStatus.OFFLINE,
            PublicationStatus.IN_TRANSLATION,
            PublicationStatus.TRANSLATED,
            PublicationStatus.AWAITING,
            PublicationStatus.SUBMITTED,
            PublicationStatus.APPROVED,
        ])
    }

    public get EVENT_PYRAMID_STATUSES_TO_DISPLAY(): EventPyramidStatus[] {
        return fastClone([
            EventPyramidStatus.NEW,
            EventPyramidStatus.PITCH,
            EventPyramidStatus.DESK_ASSIGNED,
            EventPyramidStatus.WORK_IN_PROGRESS,
            EventPyramidStatus.PRODUCTION_SUBMITTED,
            EventPyramidStatus.PRODUCTION_APPROVED,
            EventPyramidStatus.LOCALISING,
            EventPyramidStatus.APPROVED_IN_ALL_LANGUAGES,
            EventPyramidStatus.PUBLISHED_IN_ALL_LANGUAGES,
            EventPyramidStatus.CLOSED,
        ])
    }

    public get DESKS_TO_DISPLAY(): PyramidDesk[] {
        return fastClone([
            PyramidDesk.HEADLINE,
            PyramidDesk.EUROPE_STORIES,
            PyramidDesk.GREEN,
            PyramidDesk.BUSINESS,
            PyramidDesk.CULTURE,
            PyramidDesk.TRAVEL,
            PyramidDesk.HEALTH,
            PyramidDesk.NEXT,
            PyramidDesk.EUROVIEWS,
            PyramidDesk.EU_POLICY,
            PyramidDesk.SOCIAL_MEDIA,
            PyramidDesk.NO_COMMENT,
            PyramidDesk.EU_MAGAZINES,
            PyramidDesk.SPONSORS_MAGAZINES,
        ])
    }

    public get DASHBOARD_TOOLBAR(): DashboardToolbar {
        return fastClone({
            displayedLanguages: [this.languagesService.defaultLanguage.isoCode],
            showSearch: true,
            showPrograms: true,
            showVerticals: true,
            showStoryType: true,
            showPublicationChannel: true,
            langId: [1],
            searchOpt: [
                PublicationStatus.ONLINE,
                PublicationStatus.OFFLINE,
                PublicationStatus.SCHEDULED,
                PublicationStatus.TO_REPUBLISH,
                ToolbarFilter.BREAKING_NEWS,
                ToolbarFilter.IS_MAGAZINE,
                ToolbarFilter.NEWS,
            ],
        })
    }

    public get DEFAULT_EVENTS_REQUEST_DTO_GALAXY(): DashboardSearchFilters {
        return fastClone({
            daletVersionId: DaletVersionId.GALAXY,
            contributorId: null,
            langId: [this.languagesService.defaultLanguage.id],
            programId: null,
            programDeliverableId: null,
            typeId: null,
            publicationOwnerId: null,
            publishedAt: null,
            lastPublishedAt: null,
            search: '',
            assignedTo: null,
            publicationStatus: null,
            withVideoOnly: false,
            daletTitleName: '',
            options: this.DASHBOARD_TOOLBAR.searchOpt.map(option => ({
                id: option,
                value: false,
            })),
        })
    }

    public get DEFAULT_STORIES_REQUEST_DTO(): DashboardSearchFilters {
        return fastClone({
            daletVersionId: DaletVersionId.PYRAMID,
            publicationOwnerId: null,
            assignedTo: null,
            langId: [this.languagesService.defaultLanguage.id],
            programId: null,
            contributorId: null,
            typeId: null,
            publishedAt: null,
            lastPublishedAt: null,
            search: '',
            publicationStatus: null,
            withVideoOnly: false,
            daletTitleName: '',
            deskId: null,
            options: Object.entries(ToolbarFilter).map(([_, value]) => ({ id: Number(value), value: false })),
        })
    }

    public get DEFAULT_EVENTS_REQUEST_DTO(): DashboardSearchFiltersEvents {
        return fastClone({
            daletVersionId: DaletVersionId.PYRAMID,
            assignedTo: null,
            langId: null,
            eventsWithoutProducerLang: false,
            eventsWithoutProducerLangStory: true,
            deskId: null,
            programId: null,
            programDeliverableId: null,
            publicationOwnerId: null,
            updatedAt: null,
            createdAt: null,
            search: '',
            eventPyramidStatusId: null,
            daletTitleName: '',
            options: Object.entries(ToolbarFilter).map(([_, value]) => ({ id: Number(value), value: false })),
        })
    }

    public get STORIES_WITH_VIDEO_LIST(): FilterableInterface[] {
        return fastClone([
            {
                id: 1,
                label: 'Stories with video',
                group: 'stories-with-video',
            },
        ])
    }

    public get TOOLBAR_FILTERS_LIST(): FilterableInterface[] {
        return fastClone([
            {
                id: ToolbarFilter.IS_MAGAZINE,
                label: ToolbarFilterLabel.IS_MAGAZINE,
                group: '',
            },
            {
                id: ToolbarFilter.NEWS,
                label: ToolbarFilterLabel.NEWS,
                group: '',
            },
            {
                id: ToolbarFilter.BREAKING_NEWS,
                label: ToolbarFilterLabel.BREAKING_NEWS,
                group: '',
            },
        ])
    }

    public get TOOLBAR_FILTERS_LIST_EVENTS(): FilterableInterface[] {
        return fastClone([
            ...this.TOOLBAR_FILTERS_LIST,
            {
                id: ToolbarFilter.EMBARGO,
                label: ToolbarFilterLabel.EMBARGO,
                group: '',
            },
        ])
    }

    public get DELIVERABLE_FILTERS_NAMES_MAPPING(): MappedFilterName[] {
        return fastClone([
            {
                id: 68,
                label: 'Article',
                filterName: DeliverableFilterName.DELIVERABLE_ARTICLE,
            },
            {
                id: 70,
                label: 'Article & Video',
                filterName: DeliverableFilterName.DELIVERABLE_ARTICLE_AND_VIDEO,
            },
            {
                id: 73,
                label: 'Liveblogging',
                filterName: DeliverableFilterName.DELIVERABLE_LIVEBLOGGING,
            },
            {
                id: 72,
                label: 'Master',
                filterName: DeliverableFilterName.DELIVERABLE_MASTER,
            },
            {
                id: 71,
                label: 'Podcast',
                filterName: DeliverableFilterName.DELIVERABLE_PODCAST,
            },
            {
                id: 40,
                label: 'Sujet',
                filterName: DeliverableFilterName.DELIVERABLE_SUJET,
            },
            {
                id: 42,
                label: 'Sujet Brussels',
                filterName: DeliverableFilterName.DELIVERABLE_SUJET_BRUSSELS,
            },
            {
                id: 66,
                label: 'Sujet Video',
                filterName: DeliverableFilterName.DELIVERABLE_SUJET_VIDEO,
            },
            {
                id: 62,
                label: 'Sujet Web',
                filterName: DeliverableFilterName.DELIVERABLE_SUJET_WEB,
            },
            {
                id: 69,
                label: 'Video & Summary',
                filterName: DeliverableFilterName.DELIVERABLE_VIDEO_AND_SUMMARY,
            },
            {
                id: 67,
                label: 'Web Bonus',
                filterName: DeliverableFilterName.DELIVERABLE_WEB_BONUS,
            },
        ])
    }

    public get TOOLBAR_FILTERS_NAMES_MAPPING(): MappedFilterName[] {
        return fastClone([
            {
                id: 106,
                label: 'Breaking news',
                filterName: ToolbarFilterName.BREAKING_NEWS,
            },
            {
                id: 108,
                label: 'Magazine',
                filterName: ToolbarFilterName.IS_MAGAZINE,
            },
            {
                id: 0,
                label: 'News',
                filterName: ToolbarFilterName.NEWS,
            },
        ])
    }

    public get TOOLBAR_FILTERS_NAMES_MAPPING_EVENTS(): MappedFilterName[] {
        return fastClone([
            ...this.TOOLBAR_FILTERS_NAMES_MAPPING,
            {
                id: 107,
                label: 'Embargo',
                filterName: ToolbarFilterName.EMBARGO,
            },
        ])
    }

    constructor(private languagesService: LanguagesService) {}
}
