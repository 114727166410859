import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import * as clone from 'clone'
import * as merge from 'deepmerge'
import { StoryReplicatedFields } from 'enums/replicated-fields'
import { DocumentNode } from 'graphql'
import { filter as graphqlFilter } from 'graphql-anywhere'
import { BehaviorSubject, from, Observable, of } from 'rxjs'
import { catchError, filter, pluck, switchMap } from 'rxjs/operators'
import { getStoryDaletItemcodes } from 'services/story/dalet-itemcode/query'
import {
    automateStoryUpdates,
    producerStoryUpdates,
    removeAutomateStoryUpdatedWarning,
    removeProducerStoryUpdatedWarning,
    replicateStoryDataToLangs,
} from 'services/story/translation/query'
import { networkQuery } from 'tools/apollo'
import { Story } from '../../entities/story'
import { MutationResult, QueryResult } from '../../interfaces/query-result'
import { Story as IStory } from '../../interfaces/story'
import { Iframe, Image, Video } from '../../models/media.model'
import { TitlesAndSettings } from '../../models/mutation/story/titles-and-settings'
import { StoryModel } from '../../models/story.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { HelpersDates } from '../helpers/helpersDates'
import { upsertAssignee } from './contributors/assignee'
import { updateStoryAdditionalReporting, updateStoryContributors } from './contributors/query'
import { findByIdQuery, storyMainHeaderFragmentGpl, storyWithRelatedFragmentGpl } from './find-by-id.query'
import { updateStoryMainHeader } from './main-header/query'
import { upsertTakeStoryOffline } from './offline/query'
import {
    approveStory,
    declineStory,
    publish,
    storyUrlExists,
    submitStory,
    updateStoryStatusToEdit,
} from './publication/query'
import { setStoryReadyToPublish } from './publication/set-story-ready-to-publish'
import { upsertStoryRelated } from './related/query'
import { upsertStoryTagsAndMetadata } from './tags-and-metadatas/query'
import { upsertStoryText } from './text/query'
import { StoryTextFormatsListService } from './text-format'
import { upsertStoryTitlesAndSettings } from './titles-and-settings/query'

@Injectable()
export class StoryService extends BaseService {
    protected protectedProps: (keyof StoryModel)[] = ['id']

    protected _story: BehaviorSubject<StoryModel> = new BehaviorSubject({} as StoryModel)

    /**
     * An in-memory story model we update and reuse (to stop us asking for the ...storyWithRelatedFragment in every
     * mutation response)
     */
    protected _cachedStoryModel: StoryModel

    public story: Observable<StoryModel> = this._story
        .asObservable()
        .pipe(filter((value: StoryModel) => Boolean(Object.keys(value).length)))

    constructor(
        apollo: Apollo,
        protected storyTextFormatsService: StoryTextFormatsListService,
        errorsMessagesService: ErrorsMessagesService,
    ) {
        super(apollo, errorsMessagesService)
    }

    async loadStoryAsync(storyId: number | number[], query = findByIdQuery): Promise<any> {
        return this.getLoadStoryObservable(storyId, query).toPromise()
    }

    /**
     * Main story loader
     */
    loadStory(storyId: number): Observable<QueryResult> {
        const queryResult: QueryResult = { status: 'pending' } as QueryResult

        return this.getLoadStoryObservable(storyId).pipe(
            switchMap((story: { story: StoryModel }) => {
                if (story && story.story && story.story.id === storyId) {
                    const currentStory = graphqlFilter(storyWithRelatedFragmentGpl, story.story)
                    this._cachedStoryModel = clone(currentStory)
                    this._story.next(this._cachedStoryModel)
                    queryResult.status = 'ok'

                    return of(queryResult)
                }

                if (!story) {
                    queryResult.status = 'error'
                    queryResult.error = `unexpected response for story "${storyId}", missing property story`

                    return of(queryResult)
                }

                if (!story.story) {
                    queryResult.status = 'error'
                    queryResult.error = `story "${storyId}" not found`

                    return of(queryResult)
                }

                if (story.story.id !== storyId) {
                    queryResult.status = 'error'
                    queryResult.error = `story "${storyId}" requested is different from story "${story.story.id}" received`

                    return of(queryResult)
                }

                return of(queryResult)
            }),
            catchError(err => {
                queryResult.status = 'error'
                queryResult.error = 'The requested story is not found or error happened during request.' + err
                // add an error
                this.errorMessagesServices.addErrorsMessage(err)

                return of(queryResult)
            }),
        )
    }

    /**
     * RPC like usage : when you need to retreive one story but different from the main one
     *
     * @param {number} storyId
     * @param {FetchPolicy} fetchPolicy
     * @returns {Observable<any>}
     */
    getLoadStoryObservable(
        storyId: number | number[],

        query = findByIdQuery,
    ): Observable<any> {
        const variables = {
            storyId,
        }

        return networkQuery(
            this.apollo,
            {
                query,
                variables,
            },
            ['data'],
        )
    }

    /**
     * When you need to retreive specifics properties from the main story
     *
     * @param {DocumentNode} query
     * @param {Array} purgeStoryArrays since some data are added in arrays, we need to purge those arrays before getting updates
     * @returns {Observable<QueryResult>}
     */
    getUpdatedDatas(query: DocumentNode, purgeStoryArrays: (keyof StoryModel)[] = []): Observable<QueryResult> {
        const queryResult: QueryResult = { status: 'pending' } as QueryResult
        const storyId: number = this._cachedStoryModel.id

        return this.getLoadStoryObservable(storyId, query).pipe(
            switchMap((res: { story: StoryModel }) => {
                if (res && res.story && res.story.id === storyId) {
                    const currentStory = graphqlFilter(storyWithRelatedFragmentGpl, res.story)
                    const overwriteProps: Partial<StoryModel> = {}
                    for (const key of purgeStoryArrays) {
                        Object.assign(overwriteProps, { [key]: [] })
                    }

                    this._cachedStoryModel = this.prepareStory(currentStory, overwriteProps)

                    this._story.next(this._cachedStoryModel)
                    queryResult.status = 'ok'
                    queryResult.content = this._cachedStoryModel

                    return of(queryResult)
                }

                if (!res) {
                    queryResult.status = 'error'
                    queryResult.error = `unexpected response for story "${storyId}", missing property story`

                    return of(queryResult)
                }

                if (!res.story) {
                    queryResult.status = 'error'
                    queryResult.error = `story "${storyId}" not found`

                    return of(queryResult)
                }

                if (res.story.id !== storyId) {
                    queryResult.status = 'error'
                    queryResult.error = `story "${storyId}" requested is different from story "${res.story.id}" received`

                    return of(queryResult)
                }

                return of(queryResult)
            }),
            catchError(err => {
                queryResult.status = 'error'
                queryResult.error = 'The requested story is not found or error happened during request.' + err
                // add an error
                this.errorMessagesServices.addErrorsMessage(err)

                return of(queryResult)
            }),
        )
    }

    /**
     * Return the story dalet item code
     */
    getStoryDaletItemcodes(storyId: number) {
        return networkQuery(
            this.apollo,
            {
                query: getStoryDaletItemcodes,
                variables: { storyId },
            },
            ['data', 'story'],
        )
    }

    saveTitlesAndSettings(
        storyId: number,
        eventId: number,
        langId: number,
        fieldsToReplicate: StoryReplicatedFields[],
        data: TitlesAndSettings,
    ): Observable<MutationResult> {
        // @todo use optimisticResponse to improve user experience
        return this.apollo
            .mutate({
                mutation: upsertStoryTitlesAndSettings,
                variables: {
                    storyId,
                    eventId,
                    langId,
                    fieldsToReplicate,
                    data,
                },
            })
            .pipe(
                pluck('data'),
                switchMap((story: { updateStoryTitlesAndSettings: StoryModel }) => {
                    if (story.updateStoryTitlesAndSettings && this._cachedStoryModel) {
                        this._cachedStoryModel = this.prepareStory(story.updateStoryTitlesAndSettings)
                        this._story.next(this._cachedStoryModel)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return of({ res: false, msg: err })
                }),
            )
    }

    updateStoryContributors(
        storyId: number,
        contributors: {
            authorId?: number
            producerId?: number
            videoEditorId?: number
        },
        fieldsToReplicate: StoryReplicatedFields[],
    ): Observable<MutationResult> {
        return this.apollo
            .mutate({
                mutation: updateStoryContributors,
                variables: {
                    storyId,
                    contributors,
                    fieldsToReplicate,
                },
            })
            .pipe(
                pluck('data'),
                switchMap((story: { updateStoryContributors: StoryModel }) => {
                    if (story.updateStoryContributors) {
                        this._cachedStoryModel = this.prepareStory(story.updateStoryContributors, { contributors: [] })
                        this._story.next(this._cachedStoryModel)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return of({ res: false, msg: err })
                }),
            )
    }

    updateStoryAdditionalReporting(
        storyId: number,
        agencies: string,
        contributionMessages: string,
        sourceMessage: string,
        contributors: string,
    ): Observable<MutationResult> {
        // @todo use optimisticResponse to improve user experience
        return this.apollo
            .mutate({
                mutation: updateStoryAdditionalReporting,
                variables: {
                    storyId,
                    agencies,
                    sourceMessage,
                    contributionMessages,
                    contributors,
                },
            })
            .pipe(
                pluck('data'),
                switchMap((story: { updateStoryAdditionalReporting: StoryModel }) => {
                    if (story.updateStoryAdditionalReporting && this._cachedStoryModel) {
                        this._cachedStoryModel = this.prepareStory(story.updateStoryAdditionalReporting)
                        this._story.next(this._cachedStoryModel)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    return of({ res: false, msg: err })
                }),
            )
    }

    setStoryReadyToPublish(storyId: number, url: string, publishedAt: string): Observable<MutationResult> {
        const mutationVariables = {
            storyId,
            url,
            publishedAt: HelpersDates.getDateIso(publishedAt),
        }
        // @todo use optimisticResponse to improve user experience

        return this.apollo
            .mutate({
                mutation: setStoryReadyToPublish,
                variables: mutationVariables,
            })
            .pipe(
                pluck('data'),
                switchMap((mutation: { setStoryReadyToPublish: StoryModel }) => {
                    if (mutation.setStoryReadyToPublish) {
                        this._story.next(mutation.setStoryReadyToPublish)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return of({ res: false, msg: err })
                }),
            )
    }

    updatePublication(
        storyId: number,
        channels: number[],
        publishedAt: string,
        unpublishAlert: boolean,
        alert?: any,
        pushMessage?: any,
        fieldsToReplicate?: StoryReplicatedFields[],
    ): Observable<MutationResult> {
        const dt = new Date(publishedAt)
        const mutationVariables = {
            storyId,
            channels,
            publishedAt: dt.toISOString(),
            unpublishAlert,
            fieldsToReplicate,
        }

        if (alert) {
            mutationVariables['alert'] = alert
        }

        if (pushMessage) {
            mutationVariables['pushMessage'] = pushMessage
        }

        // @todo use optimisticResponse to improve user experience
        return this.apollo
            .mutate({
                mutation: publish,
                variables: mutationVariables,
            })
            .pipe(
                pluck('data'),
                switchMap((story: { publish: StoryModel }) => {
                    if (story.publish) {
                        this._story.next(story.publish)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return of({ res: false, msg: err })
                }),
            )
    }

    saveRelated(
        storyId: number,
        fieldsToReplicate: StoryReplicatedFields[],
        related: [number],
    ): Observable<MutationResult> {
        // @todo use optimisticResponse to improve user experience
        return this.apollo
            .mutate({
                mutation: upsertStoryRelated,
                variables: {
                    storyId,
                    fieldsToReplicate,
                    related,
                },
            })
            .pipe(
                pluck('data'),
                switchMap((story: { updateStoryRelated: StoryModel }) => {
                    if (story.updateStoryRelated) {
                        this._cachedStoryModel = this.prepareStory(story.updateStoryRelated, { related: [] })
                        this._story.next(this._cachedStoryModel)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return of({ res: false, msg: err })
                }),
            )
    }

    saveText(story: IStory, formatId: number, text: string): Observable<MutationResult> {
        // @todo use optimisticResponse to improve user experience
        return this.apollo
            .mutate({
                mutation: upsertStoryText,
                variables: {
                    storyId: story.id,
                    formatId,
                    text,
                    totalChars: story.totalChars,
                },
            })
            .pipe(
                pluck('data'),
                switchMap((storyUpdated: { updateStoryText: Story }) => {
                    if (storyUpdated.updateStoryText && this._cachedStoryModel) {
                        this._cachedStoryModel = this.prepareStory(storyUpdated.updateStoryText)
                        this._story.next(this._cachedStoryModel)
                    }

                    return from(this.apollo.getClient().resetStore())
                }),
                switchMap(() => of({ res: true })),
                catchError(err => {
                    return of({ res: false, msg: err })
                }),
            )
    }

    saveTagsAndMetadata(mutationData: {
        storyId: number
        themes?: number[]
        tags: number[]
        location?: { continent?: number; country?: number; location?: number }
        fieldsToReplicate?: StoryReplicatedFields[]
        technicalTags?: number[]
    }): Observable<MutationResult> {
        return this.apollo
            .mutate({
                mutation: upsertStoryTagsAndMetadata,
                variables: {
                    ...mutationData,
                },
            })
            .pipe(
                pluck('data'),
                switchMap((story: { updateStoryTagsMetadata: StoryModel }) => {
                    if (story.updateStoryTagsMetadata) {
                        this._cachedStoryModel = this.prepareStory(story.updateStoryTagsMetadata, {
                            tags: [],
                            themes: [],
                            geo: {},
                        })
                        this._story.next(this._cachedStoryModel)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return of({ res: false, msg: err })
                }),
            )
    }

    saveMainHeader(data: {
        storyId: number
        isVideoHidden: boolean
        isLivestreamActive?: boolean
        iframe?: Iframe | null
        video: Video | null
        image: Image | null
        fieldsToReplicate?: StoryReplicatedFields[]
        file: File | null
    }): Observable<MutationResult> {
        // because the previous form was flawed and that allowed a bad Image interface in the data arguments
        // thank to this fucking destructuring javascript function
        let mutationData: any = data
        if (mutationData.image && Array.isArray(mutationData.image.metasStory)) {
            mutationData.image.metasStory = mutationData.image.metasStory[0]
        }

        // @todo use optimisticResponse to improve user experience
        if (mutationData.iframe) {
            mutationData.iframe = {
                url: '',
                ...mutationData.iframe,
                endAt: mutationData.iframe.endAt,
                startAt: mutationData.iframe.startAt,
            }
        }

        if (mutationData.video) {
            mutationData.video = {
                ...mutationData.video,
                endAt: mutationData.video.endAt,
            }
        }
        mutationData = graphqlFilter(storyMainHeaderFragmentGpl, mutationData)

        const mutationOptions = {
            mutation: updateStoryMainHeader,
            variables: {
                ...mutationData,
            },
        }

        return this.getApollo(Boolean(mutationData.file))
            .mutate(mutationOptions)
            .pipe(
                pluck('data'),
                switchMap((story: { updateStoryMainHeader: StoryModel }) => {
                    if (story.updateStoryMainHeader) {
                        this._cachedStoryModel = this.prepareStory(
                            graphqlFilter(storyWithRelatedFragmentGpl, story.updateStoryMainHeader),
                            {
                                images: [],
                                videos: [],
                                iframe: {} as Iframe,
                            },
                        )
                        this._story.next(this._cachedStoryModel)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return of({ res: false, msg: err })
                }),
            )
    }

    saveOffline(storyId: number): Observable<MutationResult> {
        return this.apollo
            .mutate({
                mutation: upsertTakeStoryOffline,
                variables: {
                    storyId,
                },
            })
            .pipe(
                pluck('data'),
                switchMap((story: { unpublish: StoryModel }) => {
                    if (story.unpublish) {
                        this._story.next(story.unpublish)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return of({ res: false, msg: err })
                }),
            )
    }

    saveAssignee(storyId: number, assigneeId: number): Observable<MutationResult> {
        // @todo use optimisticResponse to improve user experience
        return this.apollo
            .mutate({
                mutation: upsertAssignee,
                variables: {
                    storyId,
                    assigneeId,
                },
            })
            .pipe(
                pluck('data'),
                switchMap((storyUpdated: { updateStoryText: Story }) => {
                    if (storyUpdated.updateStoryText) {
                        this._story.next(storyUpdated.updateStoryText)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    return of({ res: false, msg: err })
                }),
            )
    }

    checkPublicationDateAndSlug(
        date: string,
        slug: string,
        langId: number,
        exceptForStory: number,
    ): Observable<boolean> {
        const dt = new Date(date)
        const url = HelpersDates.formatDate(dt, 'y/MM/dd') + '/' + slug

        return networkQuery(
            this.apollo,
            {
                query: storyUrlExists,
                variables: {
                    url,
                    langId,
                    exceptForStory,
                },
            },
            ['data'],
        ).pipe(switchMap((res: { storyUrlExists: boolean }) => of(res.storyUrlExists)))
    }

    replicateStoryDataToLangs(storyId: number, langIds: number[]) {
        return this.apollo
            .mutate({
                mutation: replicateStoryDataToLangs,
                variables: {
                    storyId,
                    langIds,
                },
            })
            .pipe(pluck('data'))
    }

    getProducerStoryUpdates(storyId: number): Observable<string[]> {
        return networkQuery(
            this.apollo,
            {
                query: producerStoryUpdates,
                variables: {
                    storyId,
                },
            },
            ['data', 'producerStoryUpdates'],
        )
    }

    removeProducerStoryUpdatedWarning(storyId: number) {
        return this.apollo.mutate({
            mutation: removeProducerStoryUpdatedWarning,
            variables: {
                storyId,
            },
        })
    }

    getAutomateStoryUpdates(storyId: number): Observable<string[]> {
        return networkQuery(
            this.apollo,
            {
                query: automateStoryUpdates,
                variables: {
                    storyId,
                },
            },
            ['data', 'automateStoryUpdates'],
        )
    }

    removeAutomateStoryUpdatedWarning(storyId: number) {
        return this.apollo.mutate({
            mutation: removeAutomateStoryUpdatedWarning,
            variables: {
                storyId,
            },
        })
    }

    changeStoryStatusToEdit(storyId: number): Observable<boolean> {
        return this.getApollo(false)
            .mutate({
                mutation: updateStoryStatusToEdit,
                variables: {
                    storyId,
                },
            })
            .pipe(
                pluck('data', 'updateStoryStatusToEdit'),
                switchMap((story: StoryModel) => {
                    this._cachedStoryModel = this.prepareStory(story)
                    this._story.next(this._cachedStoryModel)

                    return of(story.id === storyId)
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return of(false)
                }),
            )
    }

    submit(storyId: number): Observable<StoryModel> {
        return this.getApollo(false)
            .mutate({
                mutation: submitStory,
                variables: {
                    storyId,
                },
            })
            .pipe(
                pluck('data', 'submitStory'),
                switchMap((story: StoryModel) => {
                    this._cachedStoryModel = this.prepareStory(story)
                    this._story.next(this._cachedStoryModel)

                    return of(story)
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return this.story
                }),
            )
    }

    approve(storyId: number): Observable<StoryModel> {
        return this.getApollo(false)
            .mutate({
                mutation: approveStory,
                variables: {
                    storyId,
                },
            })
            .pipe(
                pluck('data', 'approveStory'),
                switchMap((story: StoryModel) => {
                    this._cachedStoryModel = this.prepareStory(story)
                    this._story.next(this._cachedStoryModel)

                    return of(story)
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return this.story
                }),
            )
    }

    decline(storyId: number): Observable<StoryModel> {
        return this.getApollo(false)
            .mutate({
                mutation: declineStory,
                variables: {
                    storyId,
                },
            })
            .pipe(
                pluck('data', 'declineStory'),
                switchMap((story: StoryModel) => {
                    this._cachedStoryModel = this.prepareStory(story)
                    this._story.next(this._cachedStoryModel)

                    return of(story)
                }),
                catchError(err => {
                    // add an error
                    this.errorMessagesServices.addErrorsMessage(err)

                    return this.story
                }),
            )
    }

    protected getApollo(upload: boolean) {
        if (upload) {
            return this.apollo.use('upload')
        }

        return this.apollo
    }

    protected prepareStory(modifiedProps: StoryModel, overwriteProps: Partial<StoryModel> = {}): StoryModel {
        // we must not alter the story returned by the behaviorSubject, in fact with apolloClient it cannot because object is frozen
        // Special cases --> no deep-merge we want replace properties
        // const newStory = merge(this._cachedStoryModel, overwriteProps)
        let newStory = Object.assign(this._cachedStoryModel, overwriteProps)
        newStory = merge.all([this._cachedStoryModel, modifiedProps])

        return newStory
    }
}
