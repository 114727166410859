<cms-content>
    <cms-alert-item
        [alertAppLevel]="true"
        [alertType]="'danger'"
        [alertMessage]="
            'You are currently on the new Dashboard integrating Dalet Pyramid. Do not use if your team has not made the change to this production platform.'
        "
        [alertAction]="alertAction"></cms-alert-item>
    <cms-dashboard-tags [contributor]="currentUser"></cms-dashboard-tags>
    <cms-dashboard-events [contributor]="currentUser"></cms-dashboard-events>
    <cms-dashboard-stories [contributor]="currentUser"></cms-dashboard-stories>
    <router-outlet></router-outlet>
</cms-content>
<cms-feedback-survey></cms-feedback-survey>
