import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core'
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router'
import { ClrDatagridStateInterface } from '@clr/angular'
import { DashboardPaginationAbstract } from 'app/shared/dashboard/dashboard-pagination-abstract.component'
import { DatagridFilterableComponent } from 'app/shared/filter/datagrid-filterables/datagrid-filterable.component'
import {
    ContributorDatagridFiltersInterface,
    DatagridFilterInterface,
    FilterableInterface,
} from 'app/shared/filter/datagrid-filterables/filterables.interface'
import * as dayjs from 'dayjs'
import { User } from 'entities/user'
import { ERoomType } from 'enums/contributors-notification'
import { DaletVersionId } from 'enums/dalet'
import {
    DashboardSearchFilters,
    DashboardToolbar,
    DeliverableFilterName,
    FilterKey,
    FilterName,
    FilterTypes,
    MappedFilterName,
    ToolbarFilterLabel,
    ToolbarFilterName,
} from 'enums/filters'
import { LanguageIsoCode } from 'enums/language'
import { PublicationStatus } from 'enums/publication-status'
import { StoryTypeSlug } from 'enums/story'
import { ContributorOnPages } from 'models/contributors-notification.model'
import { LanguageModel } from 'models/language.model'
import { StoryModel } from 'models/story.model'
import { Observable, Subject, combineLatest, from } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { DashboardConstantsService } from 'services/dashboard/dashboard-constants.service'
import { LanguagesService } from 'services/languages/languages'
import { ContributorsNotificationService } from 'services/notifications/contributors-notification.service'
import { VerticalService } from 'services/verticals/verticals.service'
import { decodeJSON, encodeJSON } from 'tools/json-encoder'
import { Story } from '../../../entities/story'
import { environment } from '../../../environments/environment'
import { DashboardFiltersListService } from '../../../services/dashboard/filters-list/dashboard-filters-list.service'
import { StoriesByEdition } from '../../../services/dashboard/stories-by-edition'
import { DashboardFilterHelper } from '../../../services/helpers/dashboard-filters.helper'
import { DatagridAbstractFilterComponent } from '../../shared/filter/datagrid-abstract-filter.component'
import { IAppConfig } from '../../shared/i-app-config'
import { APP_CONFIG } from '../../shared/shared.config'

@Component({
    selector: 'cms-dashboard-galaxy-edition',
    templateUrl: './dashboard-galaxy-edition.component.html',
    styleUrls: ['../dashboard-galaxy.component.scss', './dashboard-galaxy-edition.component.scss'],
})
export class DashboardGalaxyEditionComponent extends DashboardPaginationAbstract implements OnInit, OnDestroy {
    @Input() contributor: User

    @ViewChildren(DatagridFilterableComponent)
    datagridDashboardFilters: QueryList<DatagridAbstractFilterComponent<any>>
    @ViewChildren('myDate, lastPublishedAtDate')
    datagridDashboardDateFilters: QueryList<DatagridAbstractFilterComponent<any>>
    @ViewChild('languagesListFilter') languagesListFilter: DatagridFilterableComponent

    public connectedContributorData: ContributorOnPages = {}

    public channelsList: FilterableInterface[] = []
    public contributorsList: FilterableInterface[] = []
    public currentContributorList: FilterableInterface[] = []
    public languagesList: FilterableInterface[] = []
    public programDeliverablesList: FilterableInterface[] = []
    public programsList: FilterableInterface[] = []
    public statusesList: FilterableInterface[] = []
    public storiesWithVideoList: FilterableInterface[] = []
    public storyTypesList: FilterableInterface[] = []
    public toolbarFiltersList: FilterableInterface[] = []
    public verticalsList: FilterableInterface[] = []
    public indexationList: FilterableInterface[] = [{ id: 1, label: 'Published', group: '' }]

    public stories: Story[] = []

    public activeDatagridFilters: ContributorDatagridFiltersInterface = {}
    public contributorDatagridFilters: ContributorDatagridFiltersInterface = {}

    public isLoading: boolean = true
    public showRowDetails: boolean = false

    public cmsv1Host: string

    public FilterName = FilterName
    public FilterTypes = FilterTypes
    public LanguageIsoCode = LanguageIsoCode

    private dashboardSearchFilters: DashboardSearchFilters
    private dashboardToolbar: DashboardToolbar

    private nextSearch = new Subject<void>()

    get page(): number {
        return this.pageOffset / this.itemsPerPage + 1
    }

    get checkedAllLanguages(): boolean {
        return this.languagesListFilter?.value.length === this.languagesList.length
    }

    constructor(
        private authService: AuthService,
        private dashboardConstantsService: DashboardConstantsService,
        private dashboardFiltersListService: DashboardFiltersListService,
        private cdr: ChangeDetectorRef,
        private elementRef: ElementRef,
        private languagesService: LanguagesService,
        private notification: ContributorsNotificationService,
        private route: ActivatedRoute,
        private router: Router,
        private storiesByEditionService: StoriesByEdition,
        private verticalsService: VerticalService,
        @Inject(APP_CONFIG) private sharedConfig: IAppConfig,
    ) {
        super()

        this.cmsv1Host = environment.cmsv1.host
        this.itemsPerPage = this.sharedConfig.itemPerPage.dashboardEdition
        this.dashboardConstantsService.DASHBOARD_TOOLBAR.displayedLanguages = this.languagesService.languageIsoCodeList

        this.onFetchContributorsEditingStory()
    }

    public ngOnInit(): void {
        this.setDefaultFilterValues()

        // Load filters lists
        this.loadChannelsList()
        this.loadCurrentContributorList()
        this.loadLanguagesList()
        this.loadStatusesList()
        this.loadStoriesWithVideoList()

        combineLatest([
            // Load async filters lists
            this.loadProgramDeliverablesList(),
            this.loadProgramsList(),
            this.loadStoryTypesList(),
            this.loadVerticalsList(),
        ])
            .pipe(take(1))
            .subscribe(() => {
                // Init search subscriptions
                this.initNextSearchSubscription()
                this.initRouterEventsSubscription()
                this.initStoriesRefreshSubscription()
            })
    }

    /**
     * Returns true if the breaking news filter is selected
     * and the magazine and news filters should not be available
     */
    public disableNewsAndMagazineFilters(filterLabel: string): boolean {
        return (
            this.activeDatagridFilters[this.FilterName.STORY_TYPES]?.label === 'Breaking News' &&
            filterLabel !== 'Breaking news'
        )
    }

    public getStoryType(story: StoryModel): string {
        const storyTypeTranslations = story.type?.translations

        return story.type && storyTypeTranslations ? storyTypeTranslations[0].title : StoryTypeSlug.NORMAL
    }

    public isPublicationStatusOnlineOrToRepublish(story: Story): boolean {
        return (
            story.publicationStatus?.id === PublicationStatus.ONLINE ||
            story.publicationStatus?.id === PublicationStatus.TO_REPUBLISH
        )
    }

    /**
     * Apply all the contributor filters to the toolbar and the datagrid
     * and trigger a new search
     */
    public onContributorFiltersSelected(contributorFilters: ContributorDatagridFiltersInterface): void {
        this.loadToolbarFiltersList(contributorFilters)
        this.resetInactiveDashboardFilters(contributorFilters)
        this.contributorDatagridFilters = contributorFilters

        this.nextSearch.next()
    }

    /**
     * Save the datagrid filters in the activeDatagridFilters property
     * that is used to save the contributor filters and to generate the query params
     */
    public onDatagridFilterSelected(filterName: string, filter: DatagridFilterInterface | null): void {
        if (!filter) {
            delete this.activeDatagridFilters[filterName]
            this.activeDatagridFilters = { ...this.activeDatagridFilters }
            if (filterName === FilterName.LANGUAGES) {
                this.resetAllLanguagesFilters()
            }

            return
        }

        this.activeDatagridFilters = {
            ...this.activeDatagridFilters,
            [filterName]: filter,
        }
    }

    /**
     * Trigger a new search when the datagrid filters or pagination are updated
     */
    public onDatagridRefresh(state: ClrDatagridStateInterface): void {
        this.dashboardSearchFilters = this.getDashboardSearchFiltersFromDatagrid(state)

        this.pageOffset = this.getPaginationOption(state)[1]

        this.nextSearch.next()
    }

    /**
     * Trigger a new search when the toolbar deliverable filter is updated
     */
    public onDeliverableSelected(selectedDeliverable: FilterableInterface): void {
        selectedDeliverable.selected = !selectedDeliverable.selected

        const mappedFilter = this.dashboardConstantsService.DELIVERABLE_FILTERS_NAMES_MAPPING.find(
            item => item.id === selectedDeliverable.id,
        )

        this.programDeliverablesList = DashboardFilterHelper.updateSelectedValueInFilterList(
            selectedDeliverable,
            this.programDeliverablesList,
        )

        this.dashboardSearchFilters = DashboardFilterHelper.setDeliverableFilterValueInDashboardSearchFilters({
            deliverableFilter: selectedDeliverable,
            deliverableFilterKey: FilterKey.PROGRAM_DELIVERABLE_ID,
            dashboardSearchFilters: this.dashboardSearchFilters,
        })

        this.onDatagridFilterSelected(
            mappedFilter?.filterName || '',
            DashboardFilterHelper.buildFilterData(selectedDeliverable),
        )

        this.pageOffset = 0

        this.nextSearch.next()
    }

    /**
     * Trigger a new search when a toolbar filter is updated
     */
    public onToolbarFilterSelected(filter: FilterableInterface): void {
        filter.selected = !filter.selected

        if (filter.label === ToolbarFilterLabel.BREAKING_NEWS) {
            this.onDatagridFilterSelected(
                ToolbarFilterName.BREAKING_NEWS,
                DashboardFilterHelper.buildFilterData(filter),
            )

            this.applyBreakingNewsFilter(filter)

            this.dashboardConstantsService.TOOLBAR_FILTERS_NAMES_MAPPING.forEach(toolbarFilter => {
                if (toolbarFilter.label !== ToolbarFilterLabel.BREAKING_NEWS) {
                    this.resetToolbarFilterByName(toolbarFilter)
                }
            })
        } else {
            this.dashboardSearchFilters = DashboardFilterHelper.setToolbarFilterValue(
                this.dashboardSearchFilters,
                filter,
            )

            Object.entries(ToolbarFilterLabel).forEach(([key, label]) => {
                if (label === filter.label) {
                    this.onDatagridFilterSelected(ToolbarFilterName[key], DashboardFilterHelper.buildFilterData(filter))
                }
            })
        }

        this.pageOffset = 0

        this.nextSearch.next()
    }

    /**
     * Toggle the languages filter to select all or just the default language
     */
    public toggleAllLanguages(): void {
        // Clone the languages list to trigger the change detection with the selected elements
        this.languagesList = this.languagesList.map(language => {
            return {
                ...language,
                selected: !this.checkedAllLanguages || this.getContributorDefaultLanguage().id === language.id,
            }
        })
    }

    public applyBreakingNewsFilter(filter: FilterableInterface): void {
        this.dashboardSearchFilters = DashboardFilterHelper.setFilterValue(
            this.dashboardSearchFilters,
            FilterKey.BREAKING_NEWS,
            filter.selected,
        )
    }

    private applyDeliverableFilters(contributorFilters: ContributorDatagridFiltersInterface): void {
        this.programDeliverablesList = this.programDeliverablesList.map(deliverableFilter => {
            const currentFilterName =
                this.dashboardConstantsService.DELIVERABLE_FILTERS_NAMES_MAPPING.find(
                    item => item.id === deliverableFilter.id,
                )?.filterName || ''

            const isCurrentFilterIsActive = DashboardFilterHelper.filterIsActiveContributorFilter(
                currentFilterName,
                contributorFilters,
            )

            deliverableFilter.selected = isCurrentFilterIsActive

            this.dashboardSearchFilters = DashboardFilterHelper.setDeliverableFilterValueInDashboardSearchFilters({
                deliverableFilter,
                deliverableFilterKey: FilterKey.PROGRAM_DELIVERABLE_ID,
                dashboardSearchFilters: this.dashboardSearchFilters,
            })
            this.onDatagridFilterSelected(currentFilterName, DashboardFilterHelper.buildFilterData(deliverableFilter))

            return deliverableFilter
        })
    }

    private applyToolbarFilters(contributorFilters: ContributorDatagridFiltersInterface): void {
        this.toolbarFiltersList = this.toolbarFiltersList.map(toolbarFilter => {
            const currentFilterName = DashboardFilterHelper.getFilterNameFromMapping(
                toolbarFilter.id,
                this.dashboardConstantsService.TOOLBAR_FILTERS_NAMES_MAPPING,
            )
            const isCurrentFilterIsActive = DashboardFilterHelper.filterIsActiveContributorFilter(
                currentFilterName,
                contributorFilters,
            )

            toolbarFilter.selected = isCurrentFilterIsActive
            if (currentFilterName === ToolbarFilterName.BREAKING_NEWS) {
                this.applyBreakingNewsFilter(toolbarFilter)
            }
            this.dashboardSearchFilters = DashboardFilterHelper.setToolbarFilterValue(
                this.dashboardSearchFilters,
                toolbarFilter,
            )
            this.onDatagridFilterSelected(currentFilterName, DashboardFilterHelper.buildFilterData(toolbarFilter))

            return toolbarFilter
        })
    }

    private clearAllFilters(): void {
        this.resetAllToolbarFilters()
        this.resetBreakingNewsFilter()
        this.resetDeliverableFilter()
        this.datagridDashboardDateFilters.toArray().forEach(dateRangeFilter => {
            dateRangeFilter.resetValue()
        })
        this.datagridDashboardFilters.toArray().forEach(datagridFilter => {
            datagridFilter.resetValue()
        })
    }

    /**
     * Get the dashboard search filters from the datagrid state
     */
    private getDashboardSearchFiltersFromDatagrid(state: ClrDatagridStateInterface): DashboardSearchFilters {
        const filters = DatagridAbstractFilterComponent.getDatagridFilters(state) || {}
        this.setContributorDateFilter(FilterName.PUBLICATION_DATE, filters)
        this.setContributorDateFilter(FilterName.LAST_UPDATE_DATE, filters)

        return DashboardFilterHelper.applyDatagridFiltersToSearchFilters(
            this.dashboardSearchFilters,
            filters,
            this.dashboardToolbar,
        )
    }

    private getContributorDefaultLanguage(): LanguageModel {
        return (
            this.languagesService.languageByIsoCode[this.contributor.getDefaultLanguageIsoCode()] ||
            this.languagesService.defaultLanguage
        )
    }

    /**
     * Init the subscription to the nextSearch subject
     * to trigger the search when the filters are updated
     */
    private initNextSearchSubscription(): void {
        // Small debounce time to group the calls
        // when changing multiple filters at the same time
        const SIMULTANEOUS_FILTERS_DEBOUNCE_TIME = 1

        this.nextSearch
            .pipe(
                takeUntil(this.ngUnsubscribe),
                debounceTime(SIMULTANEOUS_FILTERS_DEBOUNCE_TIME),
                switchMap(() => this.reloadWithQueryParamFilters()),
            )
            .subscribe()
    }

    /**
     * Init the subscription to the router events
     * to trigger the search when the dashboard is loaded
     * using the query params
     */
    private initRouterEventsSubscription(): void {
        this.router.onSameUrlNavigation = 'reload'
        this.router.events
            .pipe(
                takeUntil(this.ngUnsubscribe),
                // Trigger only once per navigation
                filter(event => event instanceof NavigationEnd),
                // Trigger the search when the component is loaded the first time
                startWith(null),
                // Get the query params
                map(() => this.route.snapshot.queryParams),
                // Trigger when the query params change
                // Or when the dashboard needs to be reset (creation of new event)
                distinctUntilChanged(
                    (prev, curr) =>
                        !this.router.getCurrentNavigation()?.extras.state?.refreshDashboard &&
                        JSON.stringify(prev) === JSON.stringify(curr),
                ),
                switchMap(params => {
                    this.isLoading = true

                    if (this.router.getCurrentNavigation()?.extras.state?.scrollToTop) {
                        setTimeout(() => {
                            // Scroll to the top of the page when the datagrid is refreshed
                            // A timeout is needed to wait for the datagrid to be rendered so the scroll is not stopped
                            this.elementRef.nativeElement.parentElement.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            })
                        })
                    }

                    try {
                        const decodedFilters = decodeJSON<ContributorDatagridFiltersInterface>(params.filters)
                        this.onContributorFiltersSelected(decodedFilters)
                    } catch (_) {
                        this.clearAllFilters()
                    }

                    // Detect changes to trigger datagrid refresh and dashboardSearchFilters update
                    this.cdr.detectChanges()

                    this.dashboardSearchFilters.daletVersionId = DaletVersionId.GALAXY

                    // Set the page offset after detectChanges because the datagrid resets it on filter change
                    this.pageOffset = params.page ? (+params.page - 1) * this.itemsPerPage : 0

                    // On the first load, the total is 0,
                    // so we need to fake it so the page we try to reach exists
                    const totalNeededToDisplayPage = this.pageOffset + this.itemsPerPage
                    if (this.total < totalNeededToDisplayPage) {
                        this.total = totalNeededToDisplayPage
                    }

                    return this.storiesByEditionService.list({
                        filters: this.dashboardSearchFilters,
                        limit: this.itemsPerPage,
                        offset: this.pageOffset,
                        order: this.order,
                    })
                }),
            )
            .subscribe(result => {
                this.stories = result.results.map(story => new Story(story))
                this.total = result.pagination.total || 0
                this.isLoading = false
            })
    }

    /**
     * Init the refresh of the stories when the refresh event is triggered by storiesByEditionService
     */
    private initStoriesRefreshSubscription(): void {
        this.storiesByEditionService.refresh.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.nextSearch.next())
    }

    private loadChannelsList(): void {
        this.channelsList = this.dashboardFiltersListService.loadChannelFilterList()
    }

    private loadCurrentContributorList(): void {
        this.currentContributorList = this.dashboardFiltersListService.loadCurrentContributorList(this.contributor)
    }

    private loadLanguagesList(): void {
        this.languagesList = this.dashboardFiltersListService.loadlanguageFilterList()
    }

    private loadProgramDeliverablesList(): Observable<void> {
        const limit = 150
        const offset = 0
        this.dashboardFiltersListService.runProgramDeliverableListLoader(limit, offset)

        return this.dashboardFiltersListService.loadProgramDeliverablesList().pipe(
            takeUntil(this.ngUnsubscribe),
            map(deliverableList => {
                this.programDeliverablesList = deliverableList
            }),
        )
    }

    private loadProgramsList(): Observable<void> {
        this.dashboardFiltersListService.runProgramListLoader()

        return this.dashboardFiltersListService.loadProgramsList(this.contributor).pipe(
            takeUntil(this.ngUnsubscribe),
            map(programList => {
                this.programsList = programList
            }),
        )
    }

    private loadStatusesList(): void {
        this.statusesList = this.dashboardFiltersListService.loadStatusFilterList()
    }

    private loadStoryTypesList(): Observable<void> {
        return this.dashboardFiltersListService.loadStoryTypeList(this.dashboardToolbar.langId[0]).pipe(
            takeUntil(this.ngUnsubscribe),
            map(storyList => {
                this.storyTypesList = storyList
            }),
        )
    }

    private loadStoriesWithVideoList(): void {
        this.storiesWithVideoList = this.dashboardConstantsService.STORIES_WITH_VIDEO_LIST
    }

    private loadToolbarFiltersList(contributorFilters: ContributorDatagridFiltersInterface): void {
        if (DashboardFilterHelper.isEmpty(contributorFilters)) {
            this.clearAllFilters()

            return
        }
        this.applyToolbarFilters(contributorFilters)
        this.applyDeliverableFilters(contributorFilters)
    }

    private loadVerticalsList(): Observable<void> {
        this.verticalsService.getList()

        return this.verticalsService.verticals.pipe(
            takeUntil(this.ngUnsubscribe),
            map(verticals => {
                this.verticalsList = verticals.map(vertical => ({
                    id: vertical.id,
                    label: vertical.translations[0].title!,
                    group: '',
                }))
            }),
        )
    }

    /**
     * Listen to the contributors notification service to get the connected contributors
     */
    private onFetchContributorsEditingStory(): void {
        this.notification
            .onFetchData(ERoomType.STORY)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((contributorsConnectedList: ContributorOnPages) => {
                this.connectedContributorData = contributorsConnectedList
            })
    }

    /**
     * Reload the component with the query params search filters
     * and the pagination offset to launch a new search
     */
    private reloadWithQueryParamFilters(): Observable<boolean> {
        const encodedFilters = encodeJSON(this.activeDatagridFilters, { removeFalsyValues: true })

        const queryParams: Params = {
            ...(this.pageOffset ? { page: this.page } : {}),
            ...(Object.keys(this.activeDatagridFilters).length
                ? {
                      filters: encodedFilters,
                  }
                : {}),
        }

        return from(
            this.router.navigate([], {
                queryParams,
                relativeTo: this.route,
                replaceUrl: true,
                state: { scrollToTop: true },
            }),
        )
    }

    private resetAllLanguagesFilters(): void {
        this.languagesList = this.languagesList.map(language => {
            language.selected = language.id === this.getContributorDefaultLanguage().id

            return language
        })
    }

    private resetAllToolbarFilters(): void {
        this.dashboardConstantsService.TOOLBAR_FILTERS_NAMES_MAPPING.forEach(filter => {
            this.resetToolbarFilterByName(filter)
        })
    }

    private resetBreakingNewsFilter(): void {
        this.dashboardSearchFilters = DashboardFilterHelper.setFilterValue(
            this.dashboardSearchFilters,
            FilterKey.BREAKING_NEWS,
            null,
        )
    }

    private resetDashboardFilters(dashboardFilters: DatagridAbstractFilterComponent<any>[]): void {
        if (dashboardFilters?.length) {
            dashboardFilters.forEach(filter => {
                filter.resetValue()
            })
        }
    }

    private resetDeliverableFilter(): void {
        this.dashboardSearchFilters = DashboardFilterHelper.setFilterValue(
            this.dashboardSearchFilters,
            FilterKey.PROGRAM_DELIVERABLE_ID,
            null,
        )

        this.programDeliverablesList = this.programDeliverablesList.map(item => {
            item.selected = false

            return item
        })

        Object.values(DeliverableFilterName).forEach(currentDeliverableFilterName => {
            this.onDatagridFilterSelected(currentDeliverableFilterName, null)
        })
    }

    private resetInactiveDashboardFilters(contributorFilters: ContributorDatagridFiltersInterface): void {
        const filtersToReset = DashboardFilterHelper.getDisabledDashboardFilters({
            contributorFilters,
            datagridDashboardDateFilters: this.datagridDashboardDateFilters,
            datagridDashboardFilters: this.datagridDashboardFilters,
        })
        if (!filtersToReset?.length) {
            return
        }
        this.resetDashboardFilters(filtersToReset)
    }

    private resetToolbarFilterByName(toolbarFilter: MappedFilterName): void {
        // Removing the filter from activeContributorFilter (it will disappears from contributor filter list)
        this.onDatagridFilterSelected(toolbarFilter.filterName, null)
        // Set the selected filter value to false (uncheck the filter checkbox in the toolbar)
        this.toolbarFiltersList = this.toolbarFiltersList.map(filter => {
            if (toolbarFilter?.id === filter.id) {
                filter.selected = false
            }

            return filter
        })
        // Disable the filter from the stories search object so the request won't use it
        this.dashboardSearchFilters.options = this.dashboardSearchFilters.options.map(filter => {
            if (toolbarFilter?.id === filter.id) {
                filter.value = false
            }

            return filter
        })
    }

    /**
     * Save a contributor date filter in the activeDatagridFilters property
     * with the right date format
     */
    private setContributorDateFilter(filterName: string, filters: Record<string, any>): void {
        if (!filters || !filters[filterName]) {
            this.onDatagridFilterSelected(filterName, null)

            return
        }

        const currentFilter = filters[filterName]

        let [from, to] = currentFilter
        from = dayjs(from).format(this.dashboardConstantsService.DATE_FORMAT)
        to = dayjs(to).format(this.dashboardConstantsService.DATE_FORMAT)
        const label = `${filterName} : ${from} to ${to}`

        this.onDatagridFilterSelected(filterName, {
            filterTitle: filterName,
            label,
            data: currentFilter,
        })
    }

    private setCurrentContributorLanguage(): void {
        this.dashboardToolbar.langId = [this.authService.getUserDefaultLanguageId()]
    }

    /**
     * Set the default values for the datagrid and toolbar filters
     */
    private setDefaultFilterValues(): void {
        // Set the default values for the filters
        this.dashboardSearchFilters = this.dashboardConstantsService.DEFAULT_EVENTS_REQUEST_DTO_GALAXY
        this.dashboardToolbar = this.dashboardConstantsService.DASHBOARD_TOOLBAR
        this.toolbarFiltersList = this.dashboardConstantsService.TOOLBAR_FILTERS_LIST
        this.setCurrentContributorLanguage()
    }
}
