import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { StoryModalBaseAbstractComponent } from 'app/shared/base.component'
import { LanguageIsoCode } from 'enums/language'
import { PublicationStatus } from 'enums/publication-status'
import { UserRight } from 'enums/user-role'
import { MutationResult } from 'interfaces/query-result'
import { EventCreateOrUpdate, EventModel } from 'models/event.model'
import { LanguageModel } from 'models/language.model'
import { StoryModel } from 'models/story.model'
import { FormatDatePipe } from 'pipes/format-date.pipe'
import { zip } from 'rxjs'
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { StoriesByEdition } from 'services/dashboard/stories-by-edition'
import { EventService } from 'services/events/event'
import { LanguagesService } from 'services/languages/languages'
import { AlertMessage, FormControlNames, ModalTitlePrefix } from './constants'

const EMBARGO_MESSAGE_PREFIX = 'Warning, this story is under embargo. Do not publish until'

interface EventForm {
    isBreakingNews: FormControl<boolean>
    hasEmbargoDate: FormControl<boolean>
    embargoDate: FormControl<Date | null>
    instructions: FormControl<string>
}
@Component({
    selector: 'cms-edit-event',
    templateUrl: './edit-event.component.html',
    styleUrls: ['./edit-event.component.scss'],
})
export class EditEventComponent extends StoryModalBaseAbstractComponent implements OnInit, OnDestroy {
    public currentEventIsoCodes: LanguageIsoCode[] = []
    public currentEventStories: StoryModel[] = []

    public showLoader = {
        event: true,
    }
    public event: EventModel
    public eventForm: FormGroup<EventForm>
    public isoCodes = LanguageIsoCode
    public allLanguagesList: LanguageModel[] = []
    public instructionsMaxLength: number = 250
    public isModalOpen: boolean = false
    public isSaveButtonDisabled: boolean | null = true
    public onlineStories: number = 0
    public eventId: number
    public modalTitle: string
    public embargoDateAlertMessage: string | null = null

    public get now(): Date {
        return new Date()
    }

    constructor(
        protected router: Router,
        protected formBuilder: NonNullableFormBuilder,
        protected activatedRoute: ActivatedRoute,
        protected languagesService: LanguagesService,
        protected eventService: EventService,
        protected authService: AuthService,
        protected storiesEditionService: StoriesByEdition,
        protected formatDatePipe: FormatDatePipe,
    ) {
        super()
    }

    ngOnInit(): void {
        this.isModalOpen = true
        this.setModalTitlePrefix()
        this.loadLanguageLists()
        this.initEventForm()
        this.initEvent()
    }

    private setModalTitlePrefix(): void {
        this.modalTitle = this.showLoader.event ? ModalTitlePrefix.LOADING : ModalTitlePrefix.EDIT_EVENT
    }

    private loadLanguageLists(): void {
        this.allLanguagesList = this.languagesService.languages
    }

    private initEventForm(): void {
        this.eventForm = this.formBuilder.group<EventForm>({
            isBreakingNews: this.formBuilder.control(false),
            hasEmbargoDate: this.formBuilder.control(false),
            embargoDate: this.formBuilder.control(null),
            instructions: this.formBuilder.control(''),
        })
    }

    private saveButtonDisabled(): boolean | null {
        return this.eventForm?.invalid || this.eventService.saving || this.showLoader.event || this.isEventDisabled()
    }

    private disableFormControl(formControlName: FormControlNames): void {
        this.eventForm.controls[formControlName].disable()
    }

    private enableFormControl(formControlName: FormControlNames): void {
        this.eventForm.controls[formControlName].enable()
    }

    private disableEventFormControls(): void {
        this.disableFormControl(FormControlNames.IS_BREAKING_NEWS)
        this.disableFormControl(FormControlNames.INSTRUCTIONS)
        if (!this.authService.userHasRight(UserRight.UPDATE_EVENT_EMBARGO)) {
            this.disableFormControl(FormControlNames.HAS_EMBARGO_DATE)
            this.disableFormControl(FormControlNames.EMBARGO_DATE)
        }
        if (!this.eventForm.controls.hasEmbargoDate.value) {
            this.disableFormControl(FormControlNames.EMBARGO_DATE)
        }
    }

    private eventIsEditable(): boolean {
        return !this.event || this.event.isEditable
    }

    private isEventDisabled(): boolean {
        return !this.eventIsEditable()
    }

    private initEvent(): void {
        zip(this.authService.me, this.activatedRoute.paramMap)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([_, params]) => {
                this.eventId = +params.get('id')!

                this.eventService
                    .getEvent(this.eventId)
                    .pipe(
                        tap(event => {
                            if (!event) {
                                this.router.navigateByUrl('404')

                                return
                            }
                            this.event = event
                            this.currentEventIsoCodes = event.stories.map(story => story.language.isoCode)
                            this.currentEventStories = event.stories

                            this.setEmbargoAlertMessageOnUpdateEvent()

                            this.buildEventFormForUpdate()
                            this.disableEventFormControls()
                            this.showLoader.event = false
                            this.setModalTitlePrefix()
                        }),
                        takeUntil(this.ngUnsubscribe),
                    )
                    .subscribe()

                this.eventForm.controls.hasEmbargoDate.valueChanges
                    .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
                    .subscribe(value => {
                        if (value && !this.embargoDateAlertMessage) {
                            this.enableFormControl(FormControlNames.EMBARGO_DATE)
                        } else {
                            this.disableFormControl(FormControlNames.EMBARGO_DATE)
                            this.eventForm.controls.embargoDate.setValue(null)
                        }
                        this.isSaveButtonDisabled = this.saveButtonDisabled()
                    })

                this.eventForm.controls.embargoDate.valueChanges
                    .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
                    .subscribe(value => {
                        const embargoMessageRegex = new RegExp(
                            `(?<=.*)\\s*${EMBARGO_MESSAGE_PREFIX} [\\d/ :]*.(?=\\s*.*)`,
                            'g',
                        )
                        const instructionsControl = this.eventForm.controls.instructions
                        const cleanedInstructions = instructionsControl.value.replace(embargoMessageRegex, '').trim()
                        const separator = cleanedInstructions ? '\n' : ''
                        const embargoMessage = value
                            ? `${EMBARGO_MESSAGE_PREFIX} ${this.formatDatePipe.transform(value)}.`
                            : ''
                        const newInstructions = cleanedInstructions + separator + embargoMessage
                        instructionsControl.setValue(newInstructions, { emitEvent: false })
                    })
            })
    }

    private buildEventFormForUpdate(): void {
        const eventFormPatchObject = {
            isBreakingNews: this.event.isBreakingNews,
            hasEmbargoDate: !!this.event.embargoDate,
            embargoDate: this.event.embargoDate,
            instructions: this.event.productionNote || '',
        }
        this.eventForm.patchValue(eventFormPatchObject)
    }

    private setEmbargoAlertMessageOnUpdateEvent(): void {
        const publishedStories = this.event.stories.filter(s =>
            [PublicationStatus.ONLINE, PublicationStatus.TO_REPUBLISH].includes(s.publicationStatus?.id || 0),
        )

        if (publishedStories.length > 0) {
            this.embargoDateAlertMessage = publishedStories.map(s => s.language.isoCode.toUpperCase()).join(', ')
            this.onlineStories = publishedStories.length
        }
    }

    public onOpenChanged(options?: {
        resetSearchFilters?: boolean
        refreshDashboard?: boolean
        scrollToTop?: boolean
    }): void {
        this.open = false
        this.router.navigate(['/', 'dashboard'], {
            queryParamsHandling: options?.resetSearchFilters ? '' : 'preserve',
            state: { refreshDashboard: options?.refreshDashboard, scrollToTop: options?.scrollToTop },
        })
    }

    public onClickLang(isoCode: string) {
        const storyOfLang = this.currentEventStories.find(story => story.language.isoCode === isoCode)
        if (!storyOfLang) {
            return
        }
        this.router.navigate(['/', 'story', storyOfLang!.id], {
            queryParams: { lang: isoCode },
        })
    }

    private mutationResultCb(mutationResult: MutationResult): void {
        this.showLoader.event = false
        if (mutationResult.res) {
            // Update dashboard table.
            this.storiesEditionService.refresh.next()

            this.resetErrors().resetSuccess()

            this.onOpenChanged({
                resetSearchFilters: false,
                refreshDashboard: true,
                scrollToTop: false,
            })
        } else {
            this.formErrors.add(mutationResult.msg !== undefined ? mutationResult.msg : AlertMessage.UNEXPECTED_ERROR)
        }
    }

    private prepareCreateOrUpdatePayload(): EventCreateOrUpdate {
        const eventFormRawValues = this.eventForm.getRawValue()

        const payload: EventCreateOrUpdate = {
            id: this.event?.id,
            name: this.event.daletTitleName,
            editorialDesk: this.event.editorialDesk!,
            languages: [],
            programId: this.event.program.id,
            programDeliverableId: this.event.programDeliverable!.id,
            isBreakingNews: this.event.isBreakingNews,
            isSingleMedia: this.event.isSingleMedia,
            embargoDate: eventFormRawValues.embargoDate,
            producerLanguageId: this.event.producerLanguage!.id,
            isCoreLanguages: false,
            isTv: this.event.isTv,
            isMultiplex: this.event.isMultiplex,
            isDigital: this.event.isDigital,
            instructions: eventFormRawValues.instructions,
        }

        return payload
    }

    public onSubmit(): void {
        this.showLoader.event = true
        this.resetErrors().resetSuccess()
        const updateEventFormPayload = this.prepareCreateOrUpdatePayload()
        this.eventService.createOrUpdate(updateEventFormPayload).subscribe(this.mutationResultCb.bind(this))
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
    }
}
