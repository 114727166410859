import { gql } from '@apollo/client/core'
import { verticalWithEnTranslationFragment } from 'services/verticals/verticals.fragment'
import { paginationFragment } from '../shared/pagination.fragment'

export const programFragment = `
    ${verticalWithEnTranslationFragment}

    fragment programInfo on Program {
        id
        slug
        isDisabledCms
        isSelectableForCreation
        isSelectableForUpdate
        isSelectableForSearch
        publicationOwner {
            id
            slug
        }
        category {
            id
        }
        translations {
            slug
            language {
                id
                isoCode
            }
            title
        }
        mediahubOrganization {
            id
            name
            description
        }
        canonicalUrl
        daletProgramUrlSlug
        programDeliverables {
            id
            format
        }
        verticals {
            ...verticalWithEnTranslationInfo
        }
    }
`

export const programEmhCategoryFragment = `
    fragment programEmhCategoryInfo on Program {
        emhCategory
    }
`

export const programMenuFragment = `
    ${verticalWithEnTranslationFragment}

    fragment programMenuInfo on Program {
        id
        slug
        canonicalUrl
        daletProgramUrlSlug
        emhStock
        emhCategory
        isDisabledCms
        isSelectableForCreation
        isSelectableForUpdate
        isSelectableForSearch
        publicationOwner {
            id
            slug
            labelLong
        }
        verticals {
            ...verticalWithEnTranslationInfo
        }
        translations {
            slug
            title
            summary
            websiteMenu
            language {
                id
                isoCode
            }
        }
        theme {
            id
            translations(language: en) {
                title
                language {
                id
                isoCode
                }
            }
        }
        category {
            id
            label
        }
        youtubeCategory {
            id
            name
        }
        programDeliverables {
            id
            slug
            title
            format
            isActive
        }
        mediahubOrganization {
            id
            name
            description
        }
    }
`

export const programInputFragment = `
    fragment programInput on Program {
        id
        slug
        publicationOwnerId
        programCategoryId
        themeId
        daletProgramUrlSlug
        isDisabledCms
        isSelectableForCreation
        isSelectableForUpdate
        isSelectableForSearch
        canonicalUrl
        emhStock
        emhCategory
    }
`

export const programsQuery = gql`
    ${paginationFragment}
    ${programFragment}

    query programList($search: ProgramSearchInput, $options: QueryOptions) {
        programs(search: $search, options: $options) {
            pagination {
                ...paginationInfo
            }
            results {
                ...programInfo
            }
        }
    }
`

export const allProgramsForSearchQuery = gql`
    ${programFragment}

    query allProgramsForSearch {
        allProgramsForSearch {
            ...programInfo
        }
    }
`

export const programsEmhCategoriesQuery = gql`
    ${programEmhCategoryFragment}

    query programsEmhCategoriesList($search: ProgramSearchInput) {
        programs(search: $search) {
            results {
                ...programEmhCategoryInfo
            }
        }
    }
`

export const programsMenuQuery = gql`
    ${paginationFragment}
    ${programMenuFragment}
    query programMenuList($search: ProgramSearchInput, $options: QueryOptions) {
        programs(search: $search, options: $options) {
            pagination {
                ...paginationInfo
            }
            results {
                ...programMenuInfo
            }
        }
    }
`

export const programMenuByIdQuery = gql`
    ${programMenuFragment}
    query programMenuByIdQuery($id: Int!) {
        program(id: $id) {
            ...programMenuInfo
        }
    }
`

export const programMutate = gql`
    ${programMenuFragment}
    mutation program($programInfo: ProgramInput!, $translations: [ProgramTranslationInput]) {
        program(programInfo: $programInfo, translations: $translations) {
            ...programMenuInfo
        }
    }
`
